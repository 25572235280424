import {
  HomeIcon,
  CursorClickIcon,
  CameraIcon,
  ClockIcon,
  UserGroupIcon,
  ChartSquareBarIcon,
  ClipboardIcon,
  ServerIcon,
  UserIcon,
  PuzzleIcon,
  BellIcon,
} from '@heroicons/react/outline';
import { VLMPlaygroundIcon, TrafficSignalIcon } from 'src/images';
import { RootPathKey } from './model';

const MODULE_ICONS: Record<RootPathKey, JSX.Element> = {
  analytics: <ChartSquareBarIcon />,
  detection: <PuzzleIcon />,
  getStarted: <CursorClickIcon />,
  history: <ClockIcon />,
  home: <HomeIcon />,
  notification: <BellIcon />,
  resource: <CameraIcon />,
  smartTraffic: <TrafficSignalIcon />,
  systemManagement: <ServerIcon />,
  task: <ClipboardIcon />,
  user: <UserIcon />,
  userManagement: <UserGroupIcon />,
  vlmPlayground: <VLMPlaygroundIcon />,
};

export default MODULE_ICONS;

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { APIProvider } from '@vis.gl/react-google-maps';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import { DialogContainer } from 'visionai-baseui';
import RootRoutes from './router/Router';
import getLocale from './shared/libs/getLocale';
import QueryClientWrapper from './shared/services/query/QueryClientWrapper';
import LangContext, { useLangContext, useLangContextValues } from './shared/services/store/LangContext';
import en from './translations/en.json';
import zh_cn from './translations/zh-cn.json';
import zh from './translations/zh.json';

dayjs.extend(updateLocale);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

const MESSAGES: { [key: string]: { [key: string]: string } } = {
  en,
  zh,
  'zh-cn': zh_cn,
};

function App(): JSX.Element {
  const { lang } = useLangContext();
  const translation = MESSAGES[lang];

  dayjs.locale(getLocale(lang));

  return (
    <IntlProvider locale="en" messages={translation}>
      <QueryClientWrapper>
        <DialogContainer>
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY as string}>
              <RootRoutes />
            </APIProvider>
          </BrowserRouter>
        </DialogContainer>
        <ReactQueryDevtools position="bottom-right" />
      </QueryClientWrapper>
    </IntlProvider>
  );
}

function AppWrapper(): JSX.Element {
  const langContextValues = useLangContextValues();

  return (
    <LangContext.Provider value={langContextValues}>
      <App />
    </LangContext.Provider>
  );
}

export default AppWrapper;

import { defineMessages } from 'react-intl';

const PREFIX = 'General.';

export default defineMessages({
  accessDenied: {
    defaultMessage: 'Access Denied',
    id: `${PREFIX}accessDenied`,
  },
  accessDeniedDescription: {
    defaultMessage:
      'Sorry, you do not have permission to view this page.\nThis might be due to your role permissions or organizational restrictions.\nPlease contact your administrator or check your access rights if you believe this is an error.',
    id: `${PREFIX}accessDeniedDescription`,
  },
  accessDeniedFroAuth0Description: {
    defaultMessage:
      'Sorry, you do not have permission to view this page.\nIt appears you are not currently a member of the Observ platform, or you do not have the necessary permissions to access this content.\nIf you believe this is an error, please contact your administrator or support for assistance.',
    id: `${PREFIX}accessDeniedFroAuth0Description`,
  },
  all: {
    defaultMessage: 'All',
    id: `${PREFIX}all`,
  },
  analytics: {
    defaultMessage: 'Analytics',
    id: `${PREFIX}analytics`,
  },
  apply: {
    defaultMessage: 'Apply',
    id: `${PREFIX}apply`,
  },
  assigned: {
    defaultMessage: 'Assigned',
    id: `${PREFIX}assigned`,
  },
  blankError: {
    defaultMessage: 'The field cannot be blank.',
    id: `${PREFIX}blankError`,
  },
  browseFile: {
    defaultMessage: 'Browse File',
    id: `${PREFIX}browseFile`,
  },
  cancel: {
    defaultMessage: 'Cancel',
    id: `${PREFIX}cancel`,
  },
  cannotCreateTitle: {
    defaultMessage: 'Cannot create',
    id: `${PREFIX}cannotCreate`,
  },
  cannotDeleteTitle: {
    defaultMessage: 'Cannot delete',
    id: `${PREFIX}cannotDelete`,
  },
  cannotUpdateOrDeleteTitle: {
    defaultMessage: 'Cannot update or delete',
    id: `${PREFIX}cannotUpdateOrDeleteTitle`,
  },
  cannotUpdateTitle: {
    defaultMessage: 'Cannot update',
    id: `${PREFIX}cannotUpdate`,
  },
  changeYouMadeHaveNotBeenSaved: {
    defaultMessage: 'Changes you made have not been saved.',
    id: `${PREFIX}changeYouMadeHaveNotBeenSaved`,
  },
  chartVisibility: {
    defaultMessage: 'Chart Visibility',
    id: `${PREFIX}chartVisibility`,
  },
  close: {
    defaultMessage: 'Close',
    id: `${PREFIX}close`,
  },
  completed: {
    defaultMessage: 'Completed',
    id: `${PREFIX}completed`,
  },
  confirm: {
    defaultMessage: 'Confirm',
    id: `${PREFIX}confirm`,
  },
  contactSupport: {
    defaultMessage: 'Contact Support',
    id: `${PREFIX}contactSupport`,
  },
  contactUs: {
    defaultMessage: 'Contact Us',
    id: `${PREFIX}contactUs`,
  },
  continue: {
    defaultMessage: 'Continue',
    id: `${PREFIX}continue`,
  },
  copy: {
    defaultMessage: 'Copy',
    id: `${PREFIX}copy`,
  },
  create: {
    defaultMessage: 'Create',
    id: `${PREFIX}create`,
  },
  createAnyway: {
    defaultMessage: 'Create Anyway',
    id: `${PREFIX}createAnyway`,
  },
  custom: {
    defaultMessage: 'Custom',
    id: `${PREFIX}custom`,
  },
  daily: {
    defaultMessage: 'Daily',
    id: `${PREFIX}daily`,
  },
  dashboard: {
    defaultMessage: 'Dashboard',
    id: `${PREFIX}dashboard`,
  },
  default: {
    defaultMessage: 'Default',
    id: `${PREFIX}default`,
  },
  delete: {
    defaultMessage: 'Delete',
    id: `${PREFIX}delete`,
  },
  detail: {
    defaultMessage: 'Detail',
    id: `${PREFIX}detail`,
  },
  detection: {
    defaultMessage: 'Detection',
    id: `${PREFIX}detection`,
  },
  detectionWithoutScreenshots: {
    defaultMessage: 'Detection without screenshots',
    id: `${PREFIX}detectionWithoutScreenshots`,
  },
  doYouWantToLeaveThisPage: {
    defaultMessage: 'Do you want to leave this page?',
    id: `${PREFIX}doYouWantToLeaveThisPage`,
  },
  download: {
    defaultMessage: 'Download',
    id: `${PREFIX}download`,
  },
  edit: {
    defaultMessage: 'Edit',
    id: `${PREFIX}edit`,
  },
  emptyHintCameraButton: {
    defaultMessage: 'Create Your First Camera',
    id: `${PREFIX}emptyHintCameraButton`,
  },
  emptyHintCameraDescription: {
    defaultMessage: `You haven't created any locations / cameras yet. Start by creating your first location / camera to begin detecting tasks.`,
    id: `${PREFIX}emptyHintCameraDescription`,
  },
  emptyHintCameraLink: {
    defaultMessage: 'Learn more about Camera',
    id: `${PREFIX}emptyHintCameraLink`,
  },
  emptyHintCameraTaskButton: {
    defaultMessage: 'Create Your First Task',
    id: `${PREFIX}emptyHintCameraTaskButton`,
  },
  emptyHintCameraTaskDescription: {
    defaultMessage: `You haven't created any camera tasks yet. Start by creating your first task to begin monitoring and analyzing events.`,
    id: `${PREFIX}emptyHintCameraTaskDescription`,
  },
  emptyHintCameraTaskLink: {
    defaultMessage: 'Learn more about Task',
    id: `${PREFIX}emptyHintCameraTaskLink`,
  },
  emptyHintCameraTaskTitle: {
    defaultMessage: 'Get Started with Your First Task',
    id: `${PREFIX}emptyHintCameraTaskTitle`,
  },
  emptyHintCameraTitle: {
    defaultMessage: 'Add your Camera',
    id: `${PREFIX}emptyHintCameraTitle`,
  },
  emptyHintComputeTargetDescription: {
    defaultMessage: `You haven't created any compute targets yet. Start by creating your first compute target to begin monitoring and analyzing events.`,
    id: `${PREFIX}emptyHintComputeTargetDescription`,
  },
  emptyHintComputeTargetLink: {
    defaultMessage: 'Learn more about system settings',
    id: `${PREFIX}emptyHintComputeTargetLink`,
  },
  emptyHintComputeTargetTitle: {
    defaultMessage: 'Add Compute Target',
    id: `${PREFIX}emptyHintComputeTargetTitle`,
  },
  emptyHintDatasetButton: {
    defaultMessage: 'Create Your First Task',
    id: `${PREFIX}emptyHintDatasetButton`,
  },
  emptyHintDatasetDescription: {
    defaultMessage: `You haven't created any dataset yet. Start by creating your first dataset to prepare model training in DataVerse.`,
    id: `${PREFIX}emptyHintDatasetDescription`,
  },
  emptyHintDatasetLink: {
    defaultMessage: 'Learn more about export dataset to DataVerse',
    id: `${PREFIX}emptyHintDatasetLink`,
  },
  emptyHintDatasetTitle: {
    defaultMessage: 'Dataset to DataVerse',
    id: `${PREFIX}emptyHintDatasetTitle`,
  },
  emptyHintEventLogButton: {
    defaultMessage: 'Check on Tasks',
    id: `${PREFIX}emptyHintEventLogButton`,
  },
  emptyHintEventLogDescription1: {
    defaultMessage: `Currently, no events have been detected, indicating that operations are running smoothly.`,
    id: `${PREFIX}emptyHintEventLogDescription1`,
  },
  emptyHintEventLogDescription2: {
    defaultMessage: `While this is typically a positive sign, understanding how to configure and trigger events can be beneficial.`,
    id: `${PREFIX}emptyHintEventLogDescription2`,
  },
  emptyHintEventLogLink1: {
    defaultMessage: 'Learn more about event parameters',
    id: `${PREFIX}emptyHintEventLogLink1`,
  },
  emptyHintEventLogLink2: {
    defaultMessage: 'Learn more about AI confidence score',
    id: `${PREFIX}emptyHintEventLogLink2`,
  },
  emptyHintEventLogLink3: {
    defaultMessage: 'Trouble shooting: no events detected?',
    id: `${PREFIX}emptyHintEventLogLink3`,
  },
  emptyHintEventLogTitle: {
    defaultMessage: 'No Events Detected',
    id: `${PREFIX}emptyHintEventLogTitle`,
  },
  emptyHintModelButton: {
    defaultMessage: 'Import Model',
    id: `${PREFIX}emptyHintModelButton`,
  },
  emptyHintModelDescription: {
    defaultMessage: `You haven't created any model yet. Start by creating your first model from DataVerse.`,
    id: `${PREFIX}emptyHintModelDescription`,
  },
  emptyHintModelLink: {
    defaultMessage: 'Learn more about model import from DataVerse',
    id: `${PREFIX}emptyHintModelLink`,
  },
  emptyHintModelTitle: {
    defaultMessage: 'Import Model from DataVerse',
    id: `${PREFIX}emptyHintModelTitle`,
  },
  emptyHintOSMUploadFailedButton: {
    defaultMessage: 'Upload Location OSM File',
    id: `${PREFIX}emptyHintOSMUploadFailedButton`,
  },
  emptyHintOSMUploadFailedDescription: {
    defaultMessage: `The system has detected that the OSM file for this location is missing some essential geographic information. \nPlease upload a new OSM file with complete data.`,
    id: `${PREFIX}emptyHintOSMUploadFailedDescription`,
  },
  emptyHintOSMUploadFailedTitle: {
    defaultMessage: 'Insufficient Geographic Information',
    id: `${PREFIX}emptyHintOSMUploadFailedTitle`,
  },
  emptyHintOSMUploadProcessingDescription: {
    defaultMessage: `The system is currently verifying if the OpenStreetMap (OSM) for this location includes the required geographic information, such as road intersections, traffic signs, and building footprints.\nThis process may take approximately 10 minutes. Please wait for it to complete.`,
    id: `${PREFIX}emptyHintOSMUploadProcessingDescription`,
  },
  emptyHintOSMUploadProcessingTitle: {
    defaultMessage: 'Checking Location Data ...',
    id: `${PREFIX}emptyHintOSMUploadProcessingTitle`,
  },
  emptyHintTrafficAssetButton: {
    defaultMessage: 'Create Your Location',
    id: `${PREFIX}emptyHintTrafficAssetButton`,
  },
  emptyHintTrafficAssetDescription: {
    defaultMessage: `You haven't created any smart traffic asset yet. Start by creating your first location to begin smart traffic detecting tasks.`,
    id: `${PREFIX}emptyHintTrafficAssetDescription`,
  },
  emptyHintTrafficAssetLink: {
    defaultMessage: 'Learn more about Smart Traffic',
    id: `${PREFIX}emptyHintTrafficAssetLink`,
  },
  emptyHintTrafficAssetTitle: {
    defaultMessage: 'Create Smart Traffic Asset',
    id: `${PREFIX}emptyHintTrafficAssetTitle`,
  },
  emptyHintVideoButton: {
    defaultMessage: 'Create Your First Video',
    id: `${PREFIX}emptyHintVideoButton`,
  },
  emptyHintVideoDescription: {
    defaultMessage: `You haven't created any videos yet. Start by creating your first video to begin detecting tasks.`,
    id: `${PREFIX}emptyHintVideoDescription`,
  },
  emptyHintVideoLink: {
    defaultMessage: 'Learn more about Video',
    id: `${PREFIX}emptyHintVideoLink`,
  },
  emptyHintVideoTaskButton: {
    defaultMessage: 'Create Your Video Task',
    id: `${PREFIX}emptyHintVideoTaskButton`,
  },
  emptyHintVideoTaskDescription: {
    defaultMessage: `You haven't created any video tasks yet. Start by creating your first task to begin monitoring and analyzing events.`,
    id: `${PREFIX}emptyHintVideoTaskDescription`,
  },
  emptyHintVideoTaskLink: {
    defaultMessage: 'Learn more about Task',
    id: `${PREFIX}emptyHintVideoTaskLink`,
  },
  emptyHintVideoTaskTitle: {
    defaultMessage: 'Get Started with Your Video Task',
    id: `${PREFIX}emptyHintVideoTaskTitle`,
  },
  emptyHintVideoTitle: {
    defaultMessage: 'Upload Video',
    id: `${PREFIX}emptyHintVideoTitle`,
  },
  enableDataVerse: {
    defaultMessage: 'Enable DataVerse',
    id: `${PREFIX}enableDataVerse`,
  },
  error: {
    defaultMessage: 'Error',
    id: `${PREFIX}error`,
  },
  errorBoundarySubtitle: {
    defaultMessage: `We've encountered an unexpected issue while loading the page.\nWe apologize for the inconvenience.`,
    id: `${PREFIX}errorBoundarySubtitle`,
  },
  errorBoundaryTips1: {
    defaultMessage: `Try refreshing the page.`,
    id: `${PREFIX}errorBoundaryTips1`,
  },
  errorBoundaryTips2: {
    defaultMessage: `Ensure you're connected to the internet and try again.`,
    id: `${PREFIX}errorBoundaryTips2`,
  },
  errorBoundaryTips3: {
    defaultMessage: `If the problem persists, please contact our support team for assistance.`,
    id: `${PREFIX}errorBoundaryTips3`,
  },
  errorBoundaryTitle: {
    defaultMessage: 'Oops, something went wrong.',
    id: `${PREFIX}errorBoundaryTitle`,
  },
  event: {
    defaultMessage: 'Event',
    id: `${PREFIX}event`,
  },
  eventEditor: {
    defaultMessage: 'Event Editor',
    id: `${PREFIX}eventEditor`,
  },
  export: {
    defaultMessage: 'Export',
    id: `${PREFIX}export`,
  },
  falseAlarm: {
    defaultMessage: 'False Alarm',
    id: `${PREFIX}falseAlarm`,
  },
  filter: {
    defaultMessage: 'Filter',
    id: `${PREFIX}filter`,
  },
  from: {
    defaultMessage: 'From',
    id: `${PREFIX}from`,
  },
  getStarted: {
    defaultMessage: 'Get Started',
    id: `${PREFIX}getStarted`,
  },
  goToVisionAIDashboard: {
    defaultMessage: 'Go to VisionAI Dashboard',
    id: `${PREFIX}goToVisionAIDashboard`,
  },
  gotIt: {
    defaultMessage: 'Got it',
    id: `${PREFIX}gotIt`,
  },
  history: {
    defaultMessage: 'History',
    id: `${PREFIX}history`,
  },
  home: {
    defaultMessage: 'Home',
    id: `${PREFIX}home`,
  },
  import: {
    defaultMessage: 'Import',
    id: `${PREFIX}import`,
  },
  integerError: {
    defaultMessage: 'Please input a positive integer.',
    id: `${PREFIX}integerError`,
  },
  lastUpdate: {
    defaultMessage: 'Last Update',
    id: `${PREFIX}lastUpdate`,
  },
  learnMore: {
    defaultMessage: 'Learn more',
    id: `${PREFIX}learnMore`,
  },
  learnMoreAboutCreatingTask: {
    defaultMessage: 'Learn more about Creating Task',
    id: `${PREFIX}learnMoreAboutCreatingTask`,
  },
  learnMoreAboutEventEditor: {
    defaultMessage: 'Learn more about Event Editor',
    id: `${PREFIX}learnMoreAboutEventEditor`,
  },
  learnMoreAboutGroupCamera: {
    defaultMessage: 'Learn more about Multi-Camera Group',
    id: `${PREFIX}learnMoreAboutGroupCamera`,
  },
  leave: {
    defaultMessage: 'leave',
    id: `${PREFIX}Leave`,
  },
  leaveWithoutSaving: {
    defaultMessage: 'Leave without saving',
    id: `${PREFIX}eaveWithoutSaving`,
  },
  linkerVisionWebsite: {
    defaultMessage: 'Linker Vision Website',
    id: `${PREFIX}linkerVisionWebsite`,
  },
  loading: {
    defaultMessage: 'Loading ...',
    id: `${PREFIX}loading`,
  },
  moreInformation: {
    defaultMessage: 'More information',
    id: `${PREFIX}moreInformation`,
  },
  na: {
    defaultMessage: 'N/A',
    id: `${PREFIX}na`,
  },
  next: {
    defaultMessage: 'Next',
    id: `${PREFIX}next`,
  },
  nextRecord: {
    defaultMessage: 'Next',
    id: `${PREFIX}nextRecord`,
  },
  no: {
    defaultMessage: 'No',
    id: `${PREFIX}no`,
  },
  noData: {
    defaultMessage: 'No Data.',
    id: `${PREFIX}noData`,
  },
  notifications: {
    defaultMessage: 'Notifications',
    id: `${PREFIX}notifications`,
  },
  observ: {
    defaultMessage: 'Observ',
    id: `${PREFIX}observ`,
  },
  observDocumentation: {
    defaultMessage: 'Observ Documentation',
    id: `${PREFIX}observDocumentation`,
  },
  off: {
    defaultMessage: 'Off',
    id: `${PREFIX}off`,
  },
  on: {
    defaultMessage: 'On',
    id: `${PREFIX}on`,
  },
  optional: {
    defaultMessage: 'Optional',
    id: `${PREFIX}optional`,
  },
  or: {
    defaultMessage: 'or',
    id: `${PREFIX}or`,
  },
  pageNotFound: {
    defaultMessage: '404 Page not found',
    id: `${PREFIX}pageNotFound`,
  },
  pageNotFoundDescription: {
    defaultMessage: `Oops! The page you're looking for doesn't exist or may have been moved.\nPlease check the URL for any errors and try again.\nIf you continue to encounter this issue, you can return to the Observ Home page for more options.`,
    id: `${PREFIX}pageNotFoundDescription`,
  },
  paginationInfoText: {
    defaultMessage: 'Showing {startRow} to {endRow} of {totalRows} results',
    id: `${PREFIX}paginationInfoText`,
  },
  previous: {
    defaultMessage: 'Previous',
    id: `${PREFIX}previous`,
  },
  previousRecord: {
    defaultMessage: 'Previous',
    id: `${PREFIX}previousRecord`,
  },
  refreshPage: {
    defaultMessage: 'Refresh Page',
    id: `${PREFIX}refreshPage`,
  },
  remove: {
    defaultMessage: 'Remove',
    id: `${PREFIX}remove`,
  },
  resetDefault: {
    defaultMessage: 'Reset Default',
    id: `${PREFIX}resetDefault`,
  },
  resource: {
    defaultMessage: 'Resource',
    id: `${PREFIX}resource`,
  },
  returnToObservHome: {
    defaultMessage: 'Return to Observ Home',
    id: `${PREFIX}returnToObservHome`,
  },
  save: {
    defaultMessage: 'Save',
    id: `${PREFIX}save`,
  },
  saveSuccess: {
    defaultMessage: 'Save success!',
    id: `${PREFIX}saveSuccess`,
  },
  scenario: {
    defaultMessage: 'Scenario',
    id: `${PREFIX}scenario`,
  },
  smartTraffic: {
    defaultMessage: 'Smart Traffic',
    id: `${PREFIX}smartTraffic`,
  },
  stay: {
    defaultMessage: 'Stay',
    id: `${PREFIX}stay`,
  },
  stop: {
    defaultMessage: 'Stop',
    id: `${PREFIX}stop`,
  },
  success: {
    defaultMessage: 'Success',
    id: `${PREFIX}success`,
  },
  systemManagement: {
    defaultMessage: 'System Management',
    id: `${PREFIX}systemManagement`,
  },
  task: {
    defaultMessage: 'Task',
    id: `${PREFIX}task`,
  },
  taskDetail: {
    defaultMessage: 'Task Detail',
    id: `${PREFIX}taskDetail`,
  },
  tbc: {
    defaultMessage: 'TBC',
    id: `${PREFIX}tbc`,
  },
  theImageNoExist: {
    defaultMessage: 'The image no longer exists',
    id: `${PREFIX}theImageNoExist`,
  },
  timeRange: {
    defaultMessage: 'Time Range',
    id: `${PREFIX}timeRange`,
  },
  to: {
    defaultMessage: 'To',
    id: `${PREFIX}to`,
  },
  top: {
    defaultMessage: 'Top',
    id: `${PREFIX}top`,
  },
  topHelpText: {
    defaultMessage:
      'Show the focusing items according to the value order on every chart.The item will apply on the event charts.',
    id: `${PREFIX}topHelpText`,
  },
  update: {
    defaultMessage: 'Update',
    id: `${PREFIX}update`,
  },
  upgradeButton: {
    defaultMessage: 'Upgrade Subscription',
    id: `${PREFIX}upgradeButton`,
  },
  upgradeDescription: {
    defaultMessage: `You've reached the limit of 2 active tasks allowed under your 14-day free trial plan. Upgrade to our "Pay As
          You Go" plan for full access!`,
    id: `${PREFIX}upgradeDescription`,
  },
  upgradeItem1: {
    defaultMessage:
      'Execute as many tasks as you need, ensuring your detections are always moving forward without delay.',
    id: `${PREFIX}upgradeItem1`,
  },
  upgradeItem2: {
    defaultMessage: 'Expand your capabilities with access to an extensive library of predefined scenarios.',
    id: `${PREFIX}upgradeItem2`,
  },
  upgradeItem3: {
    defaultMessage: 'Unlock the full potential of customizability by operating your own custom scenario events.',
    id: `${PREFIX}upgradeItem3`,
  },
  upgradeTitle: {
    defaultMessage: 'Upgrade to Launch More Tasks',
    id: `${PREFIX}upgradeTitle`,
  },
  uploading: {
    defaultMessage: 'Uploading...',
    id: `${PREFIX}uploading`,
  },
  userManagement: {
    defaultMessage: 'User Management',
    id: `${PREFIX}userManagement`,
  },
  userProfile: {
    defaultMessage: 'User Profile',
    id: `${PREFIX}userProfile`,
  },
  view: {
    defaultMessage: 'View',
    id: `${PREFIX}view`,
  },
  viewProfile: {
    defaultMessage: 'View Profile',
    id: `${PREFIX}viewProfile`,
  },
  vlmPlayground: {
    defaultMessage: 'VLM Playground',
    id: `${PREFIX}vlmPlayground`,
  },
  warning: {
    defaultMessage: 'Warning',
    id: `${PREFIX}warning`,
  },
  weekly: {
    defaultMessage: 'Weekly',
    id: `${PREFIX}weekly`,
  },
  workspace: {
    defaultMessage: 'workspace',
    id: `${PREFIX}workspace`,
  },
  yes: {
    defaultMessage: 'Yes',
    id: `${PREFIX}yes`,
  },
});

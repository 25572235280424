import { apiGet } from 'src/shared/services/api/apiBase';
import { UserPermission } from '../../domain/model/user';
import { UserPermissionDto } from './userDto';

function getPermissionFromDto(dto: UserPermissionDto[]): UserPermission[] {
  return dto.map(({ id, name, permissions: [permission] }) => {
    const {
      analytics: { filter: filterAnalytics, view: viewAnalytics },
      cameraTask: {
        add,
        addGroup,
        addVideo,
        change,
        changeAIConfidence,
        changeCamera,
        changeGroupMembers,
        changeLocation,
        changeNotificationTargets,
        changeROI,
        changeSystemSetting,
        changeVideoRecordToggle,
        changeZone,
        delete: deleteTask,
        pause,
        record,
        snapshot,
        terminate,
        view: viewTask,
        viewSystemSetting,
      },
      detection: { view: viewAiSetting },
      deviceManagement: { changeModelDeployment, changeStorageDay, view: viewSystemManagement },
      eventLog: { changeNote, changeStatus },
      history: { filter: filterHistory, view: viewHistory },
      resource: {
        add: addLocationCamera,
        change: changeLocationCamera,
        delete: deleteLocationCamera,
        video: crudVideo,
        view: viewResource,
      },
      userManagement: {
        add: addUser,
        addOrganization,
        changeOrganization,
        changeOrganizationMembers,
        delete: deleteUser,
        view: viewUserManagement,
      },
      vehicleAnalytics: { filter: filterVehicleAnalytics, view: viewVehicleAnalytics },
      vehicleDetection: {
        add: addVehicleDetection,
        change: changeVehicleDetection,
        filter: filterVehicleDetection,
        view: viewVehicleDetection,
      },
      vehicleEventLog: { changeStatus: changeVehicleStatus },
      vehicleHistory: { filter: filterVehicleHistory, view: viewVehicleHistory },
      vehicleSystemManagement: { filter: filterVehicleSystemManagement, view: viewVehicleSystemManagement },
      vehicleTask: {
        delete: deleteVehicleTask,
        filter: filterVehicleTask,
        pause: pauseVehicleTask,
        restart: reStartVehicleTask,
        view: viewVehicleTask,
      },
      vlm: { add: addVLMSetting, change: editVLMSetting, view: viewVLMPage },
    } = permission;

    return {
      id,
      name,
      permission: {
        analytics: {
          canUseFilter: filterAnalytics,
          canViewPage: viewAnalytics,
        },
        detection: {
          canEditEvent: viewAiSetting,
          canEditScenario: viewAiSetting,
          canViewPage: viewAiSetting,
        },
        history: {
          canUseFilter: filterHistory,
          canViewPage: viewHistory,
        },
        resource: {
          canAddCamera: addLocationCamera,
          canAddLocation: addLocationCamera,
          canAddVideo: crudVideo,
          canDeleteCamera: deleteLocationCamera,
          canDeleteLocation: deleteLocationCamera,
          canDeleteVideo: crudVideo,
          canEditCamera: changeLocationCamera,
          canEditLocation: changeLocationCamera,
          canEditVideo: crudVideo,
          canViewCamera: viewResource,
          canViewLocation: viewResource,
          canViewPage: viewResource,
          canViewVideo: crudVideo,
        },
        systemManagement: {
          canViewPage: viewSystemManagement,
          editModelDeployment: changeModelDeployment,
          editStorageDay: changeStorageDay,
        },
        taskDashboard: {
          canAddGroupTask: addGroup,
          canAddITSTask: add,
          canAddMultiCameraGroupTask: add,
          canAddTask: add,
          canAddVideoTask: addVideo,
          canEditBatchTask: change,
          canViewPage: viewTask,
          canViewTask: viewTask,
          canViewVideoTask: addVideo,
        },
        taskDetail: {
          canDeleteTask: deleteTask,
          canPauseTask: pause,
          canTerminateTask: terminate,
          canViewPage: viewTask,
          canViewSetting: viewSystemSetting,
          eventLog: {
            canEditNote: changeNote,
            canEditStatus: changeStatus,
          },
          setting: {
            detection: {
              canEditAiThreshold: changeSystemSetting,
              canEditObjectSize: changeSystemSetting,
              canViewAiThreshold: viewSystemSetting,
              canViewObjectSize: viewSystemSetting,
            },
            event: {
              canEditTrigger: changeSystemSetting,
              canViewTrigger: viewSystemSetting,
            },
            task: {
              canCaptureSnapshot: snapshot,
              canEditCamera: changeCamera,
              canEditImageCollection: changeSystemSetting,
              canEditLocation: changeLocation,
              canEditNotification: changeNotificationTargets,
              canEditROI: changeROI,
              canEditTaskGroup: changeGroupMembers,
              canEditTaskName: change,
              canEditVideoRecording: changeVideoRecordToggle,
              canEditZone: changeZone,
              canRecordVideo: record,
              canUseAIConfidence: changeAIConfidence,
              canViewImageCollection: viewSystemSetting,
            },
          },
        },
        userManagement: {
          canAddOrganization: addOrganization,
          canAddUser: addUser,
          canChangeOrganizationMember: changeOrganizationMembers,
          canDeleteUser: deleteUser,
          canEditOrganization: changeOrganization,
          canEditUser: deleteUser,
          canViewPage: viewUserManagement,
        },
        vehicleAnalytics: {
          canUseFilter: viewVehicleAnalytics,
          canViewPage: filterVehicleAnalytics,
        },
        vehicleDetection: {
          canAddDataSet: addVehicleDetection,
          canDeployModel: changeVehicleDetection,
          canExportDataSet: changeVehicleDetection,
          canImportModel: addVehicleDetection,
          canUseFilter: filterVehicleDetection,
          canViewDataSet: viewVehicleDetection,
          canViewModelRepo: viewVehicleDetection,
          canViewPage: viewVehicleDetection,
        },
        vehicleHistory: {
          canDownloadEvent: viewVehicleHistory,
          canUpdateEventLog: changeVehicleStatus,
          canUseFilter: filterVehicleHistory,
          canViewPage: viewVehicleHistory,
        },
        vehicleSystemManagement: {
          canUseAnomaly: viewVehicleSystemManagement,
          canUseFilter: filterVehicleSystemManagement,
          canViewPage: viewVehicleSystemManagement,
        },
        vehicleTask: {
          canDeleteTask: deleteVehicleTask,
          canPauseTask: pauseVehicleTask,
          canResumeTask: reStartVehicleTask,
          canUseFilter: filterVehicleTask,
          canViewPage: viewVehicleTask,
        },
        vlm: {
          canAdd: addVLMSetting,
          canEdit: editVLMSetting,
          canViewPage: viewVLMPage,
        },
      },
    };
  });
}

export default async function getPermissionFromApi(): Promise<UserPermission[]> {
  const results = await apiGet<UserPermissionDto[]>('auth/users/roles');

  return getPermissionFromDto(results);
}
